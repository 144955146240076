const removeAccents = (value) =>
value
  .toLowerCase()
  .replace(/ά/g, "α")
  .replace(/έ/g, "ε")
  .replace(/ύ/g, "υ")
  .replace(/ί/g, "ι")
  .replace(/ϊ/g, "ι")
  .replace(/ΐ/g, "ι")
  .replace(/ή/g, "η")
  .replace(/ό/g, "ο")
  .replace(/ϋ/g, "υ")
  .replace(/ΰ/g, "υ")
  .replace(/ς/g, "σ")
  .replace(/ώ/g, "ω");

const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
      
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
  
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  
    if (callNow) func.apply(context, args);
  };
};

onmessage = (event) => {
    // The object that the web page sent is stored in the event.data property.
    let searchCriteria = event.data.searchCriteria;
    let requests = event.data.requests;
    let filtersArray = event.data.filtersArray;
  
    // Using that query, perform a search in user requests.
    let filteredRequests = find(searchCriteria, requests, filtersArray);
  
    // Now the search is finished. Send back the results.
    postMessage(filteredRequests);
  };
  
  // const searchRequests = (searchCriteria, requests) => {
  //   let filteredResults = [];

  //   if (searchCriteria==="") return requests;
  //   else { 
  //       var simplifiedSearchQuery = removeAccents(searchCriteria.trim()); //Remove extra spaces, accents and special letters
  //       var splitSearchQuery = simplifiedSearchQuery.split(" "); //Separate the search query word by word

  //       for (var i = 0; i < requests.length; i++)
  //       {
  //           var simplifiedRequestCaseNo = ( requests[i].processInstanceId);
  //           var simplifiedRequestCaseTitle = ( requests[i].businessKey? removeAccents( requests[i].businessKey) : removeAccents( requests[i].serviceName)); 
  //           var simplifiedRequestProtocolNo = ( requests[i].protocolNumber);
  //           var simplifiedRequestOrgTitle = removeAccents( requests[i].orgTitle);
  //           var simplifiedRequestStatus = removeAccents( requests[i].status);

  //           //Checks if the above simplified fields have all splitSearchQuery's words; if so returns true
  //           var containsAllInCaseNo = splitSearchQuery.every((queryWord) => {
  //           return simplifiedRequestCaseNo&&simplifiedRequestCaseNo.includes(queryWord);
  //           });

  //           var containsAllInCaseTitle = splitSearchQuery.every((queryWord) => {
  //           return simplifiedRequestCaseTitle&&simplifiedRequestCaseTitle.includes(queryWord);
  //           });

  //           var containsAllInProtocolNo = splitSearchQuery.every((queryWord) => {
  //           return simplifiedRequestProtocolNo&&simplifiedRequestProtocolNo.includes(queryWord);
  //           });

  //           var containsAllInOrgTitle = splitSearchQuery.every((queryWord) => {
  //           return simplifiedRequestOrgTitle&&simplifiedRequestOrgTitle.includes(queryWord);
  //           });

  //           var containsAllInStatus = splitSearchQuery.every((queryWord) => {
  //           return simplifiedRequestStatus&&simplifiedRequestStatus.includes(queryWord);
  //           });

  //           if (containsAllInCaseNo || containsAllInCaseTitle || containsAllInProtocolNo || containsAllInOrgTitle || containsAllInStatus) {
  //           filteredResults.push(requests[i]);
  //           filteredResults = filteredResults;
  //           }          
  //       }
  //       return filteredResults;
  //   }
    
  // }

  const find = (searchQuery, items, filtersArray) => {

    let filteredItems = [];
    var simplifiedSearchQuery = removeAccents(searchQuery.trim()); //Remove extra spaces, accents and special letters
    var splitSearchQuery = simplifiedSearchQuery.split(" "); //Separate the search query word by word
    items.forEach((item) => {
        let itemPropsSimplified = [];
        let containedAllInProperty = [];

        filtersArray.forEach((filter) => {
            // console.log("searchComp", filter, item[filter] );
            if (item[filter]) {
              itemPropsSimplified.push(removeAccents(item[filter]));
              itemPropsSimplified = itemPropsSimplified;
            }
        })
        //Checks if the simplifiedProps have all splitSearchQuery's words; if so returns true
        
        itemPropsSimplified.forEach((prop) => {
            const test = (queryWord) => prop.includes(queryWord);
            let contains = splitSearchQuery.every(test);

            containedAllInProperty.push(contains);
            

        });


        if (containedAllInProperty.find( el => el ===true))  filteredItems.push(item);

    });

    return filteredItems;
}